import { Button, Container } from "react-bootstrap";
import styled from "styled-components";

export const StyledContainer = styled(Container)`
  padding: 20px;
  ul {
    padding-inline-start: 16px;
  }
`;

export const Heading = styled.h1`
  color: ${(props) => props.theme.colors.balck_1000};
  font-family: "Poppins Bold";
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 0.3rem;
`;

export const Subheading = styled.h2`
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.balck_900};
  font-family: "Poppins Semibold";
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.balck_900};
  font-family: "Poppins Regular";
  font-weight: 400;
  font-size: 18px;
`;
export const ListItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.balck_900};
  font-family: "Poppins Medium";
  font-weight: 500;
`;
export const RedButton = styled.button`
  margin: 24px 0px;
  background-color: rgb(75 191 127);
  border: none;
  border-radius: 8px;
  font-weight:bold;
  padding: 30px 50px;
  font-size:26px;
  color: ${(props) => props.theme.colors.white};
  font-family: "Poppins Medium";
  font-weight: 500;

  &:hover {
    background-color: rgb(75 191 127);
    color: white;
  }

  &:focus {
    box-shadow: none;
  }
`;
