import React , { useContext }from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { UserContext } from "./../../../context/UserContext";
import Hero1 from "./../../../assets/images/img_1.png";
import Hero2 from "./../../../assets/images/img_2.png";
import Hero3 from "./../../../assets/images/img_3.png";
import Survey from "./../survey";
import './style.scss';

import {
  Heading,
  ListItem,
  Paragraph,
  RedButton,
  StyledContainer,
  Subheading,
} from "./styles";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const { city } = useContext(UserContext);

  const [isSurveyVisible, setIsSurveyVisible] = React.useState(false);

  const handleLearnMoreClick = () => {
    setIsSurveyVisible(true);
  };

  return (
    <>
      <StyledContainer fluid className="mt-2">
        <Row className="justify-content-center">
          <Col md={11}>
            <Row className="mt-3">
              <Col md={10}>
                <div className="text-center mt-0 mb-4">
                  <Image src={Hero1} fluid className="img-fluid" />
                </div>{" "}
              </Col>
            </Row>
            <Row>
              <Col className="text-md-left">
                <Paragraph>
                  August 2024 - County: Feeling overwhelmed by soaring auto
                  insurance premiums? You're not alone. As rates continue to
                  skyrocket, millions of drivers are struggling to keep up. But
                  there's a groundbreaking solution that could help you save
                  thousands on your auto insurance—introducing{" "}
                  <a href="#getQuoteButton" className="text-primary underline-none">QuickAutoQuotes</a>.
                </Paragraph>
                <Paragraph>
                  A couple of years ago, I was in the same boat—struggling with
                  unmanageable auto insurance premiums. Despite my best efforts
                  to find a better deal, it seemed impossible. One night, while
                  browsing online, I stumbled upon a revolutionary tool called
                  <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a>. Skeptical
                  but desperate, I decided to give it a shot.
                </Paragraph>
              </Col>
            </Row>
            <Row className="mb-4">
              <Paragraph className="mb-1">
              <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a> is a
                state-of-the-art tool designed to instantly find the best
                insurance rates for you. Using advanced AI algorithms,{" "}
                <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a> compares
                quotes from over 200 top providers, ensuring you get the most
                competitive rates without sacrificing coverage.
              </Paragraph>
              <Row>
                <Col md={10}>
                  <div className="text-center mt-0 mb-4">
                    <Image src={Hero2} fluid className="img-fluid" />
                  </div>
                </Col>
              </Row>
              <Paragraph className="mb-1">
                After using   <a href="#getQuoteButton" className="text-primary underline-none">QuickAutoQuotes</a>, I
                was shocked by the results. My annual premium plummeted from
                $1,800 to just $900—a 50% reduction! With the money saved, I
                finally took that family vacation we had been dreaming of.&nbsp;
                <span className="fw-bold">
                  Imagine what you could do with an extra $1,000 a year.
                </span>
              </Paragraph>
              <Row>
                <Col md={10}>
                  <div className="text-center mt-0 mb-4">
                    <Image src={Hero3} fluid className="img-fluid" />
                  </div>
                </Col>
              </Row>
              <Paragraph>
                John D. from (<span className="text-danger">{city || 'Loading...'}</span>) :
                "Thanks to <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a>, I
                saved $300 on my annual premium. This tool is a lifesaver!"
              </Paragraph>
              <Paragraph>
                Sarah L. from (<span className="text-danger">{city || 'Loading...'}</span>) :
                "With
                <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a>, I saved
                enough to renovate my kitchen. It's unbelievable how much you
                can save!"
              </Paragraph>
              <Paragraph>
                Insurance companies don’t want you to know about this tool. They
                thrive on high premiums and hidden fees. But with   <a href="#getQuoteButton" className="text-primary underline-none">QuickAutoQuotes</a>,
                you can take control. This exclusive offer is limited, as
                insurance companies are pushing back, and our ability to provide
                these savings may end soon.
              </Paragraph>
              <Paragraph>
                Curious to see how much you could save? Don’t wait! Fill out our
                quick survey to find out if you qualify for these unprecedented
                savings. It’s time to take charge of your financial future and
                enjoy the peace of mind that comes with affordable auto
                insurance.
              </Paragraph>    
              <Row id="getQuoteButton">
              {" "}
              <Col>
              {!isSurveyVisible ? (
                <div  className="text-center">
                  <RedButton onClick={handleLearnMoreClick}>
                  Get Your Free Ai Quote Now
                  </RedButton>
                </div>
                 ) : (
                  <Survey/>
                 )}
              </Col>
            </Row>
              <Paragraph className="text-center text-danger">
                Limited-Time Offer: Save Up to 50% on Your Auto Insurance
              </Paragraph>
            </Row>
            
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default Home;
