import React, {useContext} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Navbar } from "react-bootstrap";
import { StyledNavbar, HeaderWrapper } from "./styles";
import { UserContext } from "./../../../../context/UserContext";
import Heading from "../Heading";
import './style.scss';

const Header = () => {
  const { city } = useContext(UserContext);
  return (
    <HeaderWrapper>
      <StyledNavbar>
        <Container fluid> {/* Use fluid to ensure full width */}
          <Nav className="w-100 d-flex justify-content-center"> {/* Centering with Flexbox */}
            <Heading className="heading-top" level={2}>
              Breaking News in {city || ''}: Save Big on Auto Insurance with This Secret Tool
            </Heading>
          </Nav>
        </Container>
      </StyledNavbar>
    </HeaderWrapper>
  );
};

export default Header;
