import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import footerImg from "../../../../assets/images/footer-img.svg";
import shield from "../../../../assets/images/blue-shield.svg";
import trustAccredited from "../../../../assets/images/trustAccredited.svg";
import { FooterWrapper } from "./styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row className="mt-5">
          <Col md={12}>
            <div className="text-center d-flex justify-content-center gap-3 align-items-center">
              <Image src={shield} style={{ width: "20px" }} />
              <h3> Free quotes, secure form, competitive offers.</h3>
              <Image src={trustAccredited} style={{ width: "140px" }} />
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <h3 className="text-center">
              Discover quotes from over 80 providers including:
            </h3>
            <div className="text-center mt-4">
              <Image src={footerImg} fluid />
            </div>
          </Col>
        </Row>
      </Container>

      <footer className="mt-5 py-5">
        <Container>
          <div className="d-md-flex justify-content-between ">
            <div>
              <p>© 2024 QuoteWizard</p>
            </div>
            <div className="d-md-flex gap-3">
              <Link to="#">Contact</Link>
              <Link to="#">Privacy Policy </Link>
              <Link to="#">Terms of Use </Link>
              <Link to="#"> Online Tracking </Link>
              <Link to="#"> Accessibility Statement </Link>
            </div>
          </div>
        </Container>
      </footer>
    </FooterWrapper>
  );
};

export default Footer;
